import React from 'react';
import styled from 'styled-components';
import {useMobile} from '@/hooks';
import {Logo} from '@/component';
import {NAV_URL} from '@/utils/enum';
import twitter from '@/assets/images/banner/twitter.svg';
import instagram from '@/assets/images/banner/ins.svg';
import reddit from '@/assets/images/banner/reddit.svg';
import youtube from '@/assets/images/banner/youtube.svg';

interface Media {
  width: string;
  height: string;
  icon: string;
}

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 0 46px;
`;

const MobileBannerWrapper = styled(BannerWrapper)`
  justify-content: center;
`;

const Text = styled.p`
  font-family: 'Berlin Sans FB';
  font-size: 16px;
  line-height: 22px;
  min-width: 200px;
  color: #000;
  flex: 0 0 33.33%;
`;

const MediaList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 30px;
  height: 76px;
  flex: 0 0 33.33%;
  justify-content: center;
`;

const MobileMediaList = styled(MediaList)`
  height: 58px;
`

const MediaItem = styled.li<Media>`
  width: ${props => props.width};
  height: ${props => props.height};
  list-style: none;
  padding: 0 15px;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const mediaAssets = [
  {
    icon: twitter,
    width: '19.57px',
    height: '15.92px',
    name: 'twitter',
  },
  {
    icon: instagram,
    width: '17.74px',
    height: '17.74px',
    name: 'instagram',
  },
  {
    icon: youtube,
    width: '20.13px',
    height: '17.93px',
    name: 'youtube',
  },
  {
    icon: reddit,
    width: '20.22px',
    height: '14.17px',
    name: 'medium'
  },
];

export default function Banner(props: { style?: object }) {
  const mobile = useMobile();
  const text = ' ';
  // const text = 'All right reserved ...... LTD';

  if (mobile) {
    return (
      <MobileWrapper style={props.style}>
        <Logo/>
        <MobileBannerWrapper>
          <MobileMediaList>
            {mediaAssets.map(media =>
              <MediaItem
                className='can-click-jump-text'
                key={media.icon} {...media}
                width="29px"
                height="29px"
                onClick={() => NAV_URL[media.name] && window.open(NAV_URL[media.name])}/>)}
          </MobileMediaList>
        </MobileBannerWrapper>
      </MobileWrapper>
    );
  }

  return (
    <BannerWrapper style={props.style}>
      <Text>{text}</Text>
      <MediaList>
        {mediaAssets.map(media =>
          <MediaItem
            className='can-click-jump-text'
            key={media.icon} {...media}
            onClick={() => NAV_URL[media.name] && window.open(NAV_URL[media.name])}/>)}
      </MediaList>
      <Logo style={{backgroundPosition: 'right', flex: '0 0 33.3%'}}/>
    </BannerWrapper>
  );
}
