import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Loading } from '@/component';

// const Entry = lazy(() => import('./page/entry'));
// const Home = lazy(() => import('./page/home'));
// const Puffs = lazy(() => import('./page/Puffs/Puffs'));
// const Social = lazy(() => import('./page/Social/social'));
// const Game = lazy(() => import('./page/game'));
// const Create = lazy(() => import('./page/create'));
const NewHome = lazy(() => import('./page/newHome'));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path='/' element={<NewHome />} />
          {/* <Route path="/home" element={<Home/>}/> */}
          {/* <Route path="/newhome" element={<NewHome/>}/> */}
          {/* <Route path="/game" element={<Game/>}/>
          <Route path="/puffs" element={<Puffs/>}/>
          <Route path="/social" element={<Social/>}/>
          <Route path="/create" element={<Create/>}/> */}
        </Routes>
      </Suspense>
    </Router>
  );
}
