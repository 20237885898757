import { useCallback, useEffect, useState } from 'react';

export default function useMobile() {
  const threshold = 768;
  const getClientWidth = useCallback(
    () => document.body.clientWidth || document.documentElement.clientWidth,
    [],
  );
  const [mobile, setMobile] = useState(getClientWidth() <= threshold);
  const onResize = useCallback(() => {
    setMobile(getClientWidth() <= threshold);
  }, [getClientWidth]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return mobile;
}
