import styled from 'styled-components';
import logo from '@/assets/images/logo.webp';

interface ImageProps {
  border: string;
  radius: number;
}

interface MediaProps {
  width: string;
  height: string;
  icon: string;
}

interface TitleProps {
  bold: boolean | number;
  color: string;
  family: string;
  lineHeight: string;
  size: string;
}

interface SubtitleProps {
  size: string;
  color: string;
  lineHeight: string;
}

const Image = styled.img<Partial<ImageProps>>`
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.radius ? `${props.radius}px` : '25px'};
`;

const Title = styled.p<Partial<TitleProps>>`
  font-family: ${props => props.family || 'Berlin Sans FB Bold'};
  font-weight: ${props => typeof props.bold === 'number' ? props.bold : props.bold ? 'bold' : 'normal'};
  font-size: ${props => props.size};
  color: ${props => props.color || '#fff'};
`;

const Subtitle = styled.p<Partial<SubtitleProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Berlin Sans FB';
  text-align: center;
  color: ${props => props.color || '#252525'};
`;

const PuffsSubtitle = styled(Subtitle)`
  min-height: 225px;
  font-size: ${props => props.size || '25px'};
  margin-bottom: 32px;
`

const Logo = styled.div`
  width: 98px;
  height: 56px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const Media = styled.div<MediaProps>`
  display: inline-block;
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export {
  Image,
  Media,
  Logo,
  Subtitle,
  PuffsSubtitle,
  Title,
};
