import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import dinosaur from './dinosaur_run.gif';

const Container = styled.div<{ trigger: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.div<{ src: string; }>`
  width: 140px;
  height: 115px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

let timer: ReturnType<typeof setInterval> | null = null

export default function Loading({ setLoading }: any) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    timer = setInterval(() => {
      const next = progress + Math.ceil(Math.random() + 10);
      if (next > 100) {
        setProgress(100);
        setLoading(false);
      } else {
        setProgress(next);
      }
    }, 200);

    return () => {
      timer && clearInterval(timer);
    }
  }, [progress, setLoading]);

  return (
    <Container trigger={progress >= 99}>
      <Image src={dinosaur} />
    </Container>
  );
}
