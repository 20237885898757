import { Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Logo } from '../compose';
import { routes } from './route';
import Discord from './discord-mobile.webp';
import Tg from './tele-mobile.webp';
import Tw from './tw-mobile.webp';
import YTB from './youtube-mobile.webp';

interface MediaProps {
  icon: string;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 25px 0;
  background-color: #2783FA;
  z-index: 1000;
`;

const Close = styled.div`
  position: absolute;
  top: 47px;
  right: 35px;
  width: 23px;
  height: 23px;
  &:before, &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #fff;
  }
  &:before {
    left: 0;
    transform-origin: left;
    transform: rotate(-45deg);
  }
  &:after {
    right: 0;
    transform-origin: right;
    transform: rotate(45deg);
  }
`;

const NewLogo = styled(Logo)`
  width: 162px;
  height: 80px;
  margin-bottom: 0;
`;

const List = styled.ul`
  margin-bottom: 5vh;
`;

const Item = styled.li`
  margin-bottom: 30px;
  &:last-of-type { margin-bottom: 0; }
`;

const MeidaConfig = [
  { name: 'twitter', icon: Tw },
  { name: 'discord', icon: Discord },
  { name: 'Telegram', icon: Tg },
  { name: 'Youtube', icon: YTB },
];

const Media = styled.li<MediaProps>`
  width: 23px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.icon});
  background-position: center;
`;

export default function Mobile(
  { setShowMenu }: { setShowMenu: Dispatch<SetStateAction<boolean>> }
) {
  return createPortal(
    <Container>
      <NewLogo className="mb-24" />
      <Close onClick={() => setShowMenu(false)} />
      <List className="mb-36">
        {routes.map((route) => (
          <Item key={route.path}>
            <NavLink
              to={route.path}
              style={({ isActive }) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '162px',
                height: '66px',
                backgroundColor: isActive ? '#76BAFE' : '#2783FA',
                borderRadius: '32px',
                fontFamily: 'Berlin Sans FB',
                fontSize: '32px',
                lineHeight: '35px',
                textTransform: 'uppercase',
                color: '#fff',
              })}
            >
              {route.name}
            </NavLink>
          </Item>
        ))}
      </List>
      <List className="flex w-40 h-5 justify-between items-center">
        {MeidaConfig.map(media => (<Media {...media} />))}
      </List>
    </Container>,
    document.getElementById('root') as HTMLElement,
  );
}
