import {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {Logo} from '@/component';
import {useMobile} from '@/hooks';
import MobileNav from './mobile';
import {routes} from './route';

const MobileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
`;

const NavLogo = styled(Logo)`
  
`;

const Menu = styled.div`
  position: relative;
  width: 38px;
  height: 38px;
  background: rgba(35, 136, 255, 0.2);
  border-radius: 50%;

  &:before, &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 23px;
    height: 2px;
    background-color: #fff;
    border-radius: 16px;
  }

  &:before {
    top: 14px;
  }

  &:after {
    top: 22px;
  }
`;

const List = styled.ul`
  line-height: 1;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 15px 15px;
  transition: background-color 0.5s;

  &.active {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: saturate(280%) blur(20px);
  }
`;

const ListItem = styled.li`
  padding: 0 17px;
  font-family: '';
  font-family: 'Berlin Sans FB Bold';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
`;

export default function Header() {
  const mobile = useMobile();
  const [showMenu, setShowMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const eventScroller = () => {
    setIsScroll(document.documentElement.scrollTop >= 3);
  }
  useEffect(() => {
    window.addEventListener('scroll', eventScroller, true);
    return () => {
      window.removeEventListener('scroll', eventScroller, true);
    }
  }, []);
  if (mobile) {
    return (
      <>
        <MobileContent>
          <NavLogo/>
          <Menu onClick={() => setShowMenu(true)}/>
        </MobileContent>
        {showMenu ? <MobileNav setShowMenu={setShowMenu}/> : null}
      </>
    );
  }

  return (
    <List className={`${isScroll ? 'active' : ''}`}>
      {routes.map(({path, name}: { path: string; name: string; }) => (
        <ListItem key={name}>
          <NavLink
            to={path}
            style={({isActive}) => ({
              textDecoration: 'none',
              color: isActive ? '#FADA66' : '#fff',
            })}
          >
            {name}
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
}
